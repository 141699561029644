import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './MenuCima.module.css';
import { useTheme } from '../../ThemeContext';
import { MdDarkMode } from "react-icons/md";
import { FaSun } from "react-icons/fa";
import LogoAdvocacia from './RepositórioImagens/Logo Advocacia Tema Escuro.png';
import LogoAdvocaciaWhite from './RepositórioImagens/Logo Advocacia.png';
import { GrScheduleNew } from "react-icons/gr";
import { FaHome } from "react-icons/fa";
import { FaQuestion } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { FaBlogger } from "react-icons/fa";







function MenuCima() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const { isDarkMode, toggleTheme } = useTheme();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const menuRef = useRef(null);



    const toggleMobileMenu = () => {
        setMobileMenuOpen((prevMobileMenuOpen) => !prevMobileMenuOpen);
    };

    const closeMobileMenu = () => {
        setMobileMenuOpen(false);
    };

    const handleOutsideClick = (e) => {
        if (menuRef.current && !menuRef.current.contains(e.target)) {
            setMobileMenuOpen(false);
        }
    };



    useEffect(() => {
        if (mobileMenuOpen) {
            document.body.style.overflow = 'hidden';
            document.addEventListener('mousedown', handleOutsideClick);
        } else {
            document.body.style.overflow = '';
            document.removeEventListener('mousedown', handleOutsideClick);
        }
    }, [mobileMenuOpen]);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const location = useLocation();

    const handleClick = () => {
        if (location.pathname === '/') {
            window.location.reload();
        }
    };








    const theme = isDarkMode ? styles.dark : styles.light;

    return (
        <div className={`${theme} ${mobileMenuOpen ? styles.menuVisible : ''}`}>
            <div ref={menuRef}>
                {/* Camada semi-transparente para escurecer o conteúdo principal quando o menu é aberto */}
                {mobileMenuOpen && <div className={styles.overlay} onClick={closeMobileMenu}></div>}

                <div className={`${styles.topTab} ${mobileMenuOpen ? styles.active : ''}`}>
                    <div className={`${styles.menuIcon} ${mobileMenuOpen ? `${styles.active} ${styles.iconActive}` : ''}`} onClick={toggleMobileMenu}>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>

                    <button onClick={toggleTheme}>
                        Mudar Para Tema {isDarkMode ? 'Claro  ' : 'Escuro  '}
                        {isDarkMode ? <FaSun className={styles.iconClaro} /> : <MdDarkMode className={styles.iconEscuro} />}
                    </button>
                </div>

                <div className={`${styles.tabsContainer} ${mobileMenuOpen ? styles.open : ''}`}>
                    <div className={styles.AlinharBotao}>
                        <button className={styles.closeButton} onClick={closeMobileMenu}>
                            <p>X</p>
                        </button>
                    </div>

                    <div className={styles.LogoContainer} onClick={closeMobileMenu}>
                        <Link to='/' onClick={handleClick}><img src={isDarkMode ? LogoAdvocacia : LogoAdvocaciaWhite} className={styles.Logo} alt="Advocacia" /></Link>
                    </div>

                    <div className={styles.MenuContainer}>
                        <Link to='/' className={styles.tab} onClick={closeMobileMenu}>
                            {windowWidth < 1050 && <FaHome className={styles.iconProfile} />} Página Inicial
                        </Link>

                        <Link to="/Duvidas" className={styles.tab} onClick={closeMobileMenu}>
                            {windowWidth < 1050 && <FaQuestion className={styles.iconProfile} />} Principais Dúvidas
                        </Link>
                        {/* <Link to="/Blog" className={styles.tab} onClick={closeMobileMenu}>
                            {windowWidth < 1050 && <FaBlogger className={styles.iconProfile} />} Blog
                        </Link> */}

                    </div>

                    <div className={styles.ClienteContainer}>

                        <Link to="/Cadastro" className={styles.tab} onClick={closeMobileMenu}>
                            <GrScheduleNew className={styles.iconProfile} /> Agende seu Atendimento
                        </Link>
                        <Link to="/Contatos" className={styles.tab} onClick={closeMobileMenu}>
                            <FaWhatsapp className={styles.iconProfile} /> Contatos
                        </Link>

                    </div>

                    {windowWidth > 1050 && (
                        <button onClick={toggleTheme} className={styles.themeButton}>
                            {isDarkMode ? <FaSun className={styles.iconClaro} /> : <MdDarkMode className={styles.iconEscuro} />}
                        </button>
                    )}
                </div>
            </div>
        </div >
    );
}

export default MenuCima;
