import React, { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { ThemeProvider } from './ThemeContext.jsx';

// Importações de componentes comuns
import MenuCima from './componentes/MenuCima/MenuCima.jsx';
import MenuBaixo from './componentes/MenuBaixo/MenuBaixo.jsx';
import Contatos from './componentes/MenuBaixo/Contatos.jsx';
import ScrollToTop from './ScrollToTop.jsx'; // Movi a função ScrollToTop para um arquivo separado

// Importação de fallback para Suspense
const Loading = () => <div>Carregando...</div>;

// Importação de componentes com React.lazy para code splitting
const Cadastro = lazy(() => import('./componentes/AgendeSuaConsulta/Cadastro.jsx'));
const Historia = lazy(() => import('./componentes/Página Inicial/Historia.jsx'));
const FaleConosco = lazy(() => import('./componentes/Principais Dúvidas/FaleConosco.jsx'));
const MensagemInicial = lazy(() => import('./componentes/Página Inicial/MensagemInicial.jsx'));
const Apresentacao = lazy(() => import('./componentes/Página Inicial/Apresentacao.jsx'));
const Local = lazy(() => import('./componentes/Página Inicial/Local.jsx'));
const ContatosTela = lazy(() => import('./componentes/Contatos/ContatosTela.jsx'));
const NotFound = lazy(() => import('./componentes/NotFound/NotFound.jsx'));
const Blog = lazy(() => import('./componentes/Blog/Blog.jsx'));
const BlogAdm = lazy(() => import('./componentes/Blog/BlogAdm.jsx'));
const Artigos = lazy(() => import('./componentes/Blog/Artigos.jsx'));

function AppLayout({ children }) {
  return (
    <>
      <MenuCima />
      <Suspense fallback={<Loading />}>
        {children}
      </Suspense>
      <MenuBaixo />
    </>
  );
}

function App() {
  return (
    <ThemeProvider>
      <Router>
        <ScrollToTop />
        <AppLayout>
          <Routes>
            <Route path="*" element={<NotFound />} />
            <Route
              path="/"
              element={
                <>
                  <MensagemInicial />
                  <Apresentacao />
                  <Local />
                  <Historia />
                  <Contatos />
                </>
              }
            />
            <Route
              path="/Duvidas"
              element={
                <>
                  <FaleConosco />
                  <Contatos />
                </>
              }
            />
            <Route
              path="/Cadastro"
              element={
                <>
                  <Cadastro />
                  <Contatos />
                </>
              }
            />
            <Route path="/Contatos" element={<ContatosTela />} />
            <Route path="/BlogAdm" element={<BlogAdm />} />
            <Route path="/Blog" element={<Blog />} />
            <Route path="/Blog/:title" element={<Artigos />} />
          </Routes>
        </AppLayout>
      </Router>
    </ThemeProvider>
  );
}

export default App;
